<template>
  <div v-if="Object.keys(availabilities)">
    <p class="caption font-weight-bold text--secondary">Set your weekly hours available for visits</p>
    <v-row>
      <v-col cols="12" lg="8" md="8" sm="12" xl="8">
        <v-card>
          <v-list>
            <div v-for="(availability, key) in availabilities" :key="key">
              <v-list-item>
                <v-row align="baseline" justify="space-between">
                  <v-col class="d-flex justify-space-between align-center pa-1" cols="12" lg="3" md="3" sm="12" xl="3">
                    <v-checkbox
                      :false-value="false"
                      :input-value="Boolean(availability.length)"
                      :true-value="true"
                      :value="Boolean(availability.length)"
                      @change="changeCheckbox($event, key)"
                    >
                      <template v-slot:label>
                        <strong>{{ getWeekDay(key) }}</strong>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col v-if="!availability.length" cols="12" lg="7" md="7" sm="12" xl="7">
                    <p class="text--secondary text-center">Unavailable</p>
                  </v-col>
                  <v-col v-else cols="12" lg="7" md="7" sm="12" xl="7">
                    <div v-if="availability.length">
                      <div
                        v-for="(availableHours, index) in availability"
                        :key="index"
                        class="d-flex align-center mb-2"
                      >
                        <v-autocomplete
                          :class="{ copied: availableHours.copied }"
                          :hide-details="true"
                          :items="options"
                          :value="formatAvailabilityHours(availableHours.start)"
                          append-icon=""
                          dense
                          full-width
                          outlined
                          @change="onAvailableHoursChange($event, key, 'start', index)"
                        ></v-autocomplete>
                        <span class="mx-3"><v-icon small>mdi-minus</v-icon></span>
                        <v-autocomplete
                          :class="{ copied: availableHours.copied }"
                          :hide-details="true"
                          :items="options"
                          :value="formatAvailabilityHours(availableHours.end)"
                          append-icon=""
                          dense
                          full-width
                          outlined
                          type="text"
                          @change="onAvailableHoursChange($event, key, 'end', index)"
                        ></v-autocomplete>
                        <v-btn class="ml-4" fab small text @click="deleteAvailability(key, index)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    :class="{
                      'text-left': $vuetify.breakpoint.smAndDown,
                      'text-right': $vuetify.breakpoint.mdAndUp,
                    }"
                    class="pa-1"
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                    xl="2"
                  >
                    <v-btn fab small text @click="onPlusButtonClick(key)">
                      <v-icon small>mdi-plus</v-icon>
                    </v-btn>
                    <v-menu v-model="availability.menu" :close-on-content-click="false" min-width="180" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab small text v-bind="attrs" v-on="on">
                          <v-icon small>mdi-file-multiple-outline</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="(availability, day) in availabilities" :key="'M' + day">
                          <v-list-item-title class="d-flex align-center px-3">
                            <v-checkbox :value="true" @change="addDay($event, day, key)">
                              <template v-slot:label>
                                <strong>{{ getWeekDay(day) }}</strong>
                              </template>
                            </v-checkbox>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item-action class="full-width pa-3 ma-0">
                          <OutlinedButton
                            :disabled="!copyDays.length"
                            color="primary"
                            full-width
                            style="color: #2196f3; border: 1px solid #2196f3; opacity: 1"
                            text="Apply"
                            @onClick="onApplyHandler(key)"
                          />
                        </v-list-item-action>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider v-if="key !== 'Saturday'"></v-divider>
            </div>
          </v-list>
          <div class="calendar_timezone">
            <span>
              <span class="calendar_timezone__bold">
                {{ $t("practitioner.appointments.editAvailability.activeTimezone", { timezone: renderedTimeZone }) }}
              </span>
              {{ $t("practitioner.appointments.editAvailability.timezoneHint") }}
              <router-link to="/practitioner/settings">{{
                $t("practitioner.appointments.editAvailability.here")
              }}</router-link>
            </span>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="12" xl="4">
        <v-card>
          <v-card-title class="weekly--overview__title">Weekly schedule overview</v-card-title>
          <v-card-text>
            <v-list v-if="availabilities && Object.keys(availabilities)">
              <v-list-item v-for="(availability, key) in availabilities" :key="key" class="justify-space-between pa-0">
                <v-col>
                  <strong>{{ getWeekDay(key) }}</strong>
                </v-col>
                <v-col class="text-right">
                  <p v-if="!availability.length" class="text--secondary mb-0">Unavailable</p>
                  <div v-else>
                    <strong v-for="(availableHours, index) in availability" :key="'a' + index" class="d-block">
                      <span v-if="availableHours.start && availableHours.end">
                        {{ formatAvailabilityHours(availableHours.start) }} -
                        {{ formatAvailabilityHours(availableHours.end) }}
                      </span>
                    </strong>
                  </div>
                </v-col>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { mapActions, mapState, mapWritableState } from "pinia";

import OutlinedButton from "@/components/uikit/OutlinedButton";
import { useAuthStore } from "@/pinia-store/auth";
import { defaultHoursAvailability, useSlotsStore } from "@/pinia-store/slots";
import { availabilityHours } from "@/utils/availabilityHours";
import { convertDayNames } from "@/utils/locales.helpers";

export default {
  name: "DefaultHours",
  components: {
    OutlinedButton,
  },
  data: () => ({
    options: availabilityHours(),
    copyDays: [],
  }),
  watch: {
    availabilities(val) {
      if (!val?.["1"]) this.availabilities = defaultHoursAvailability;
    },
  },
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapWritableState(useSlotsStore, ["availabilities", "initialAvailabilities"]),
    week() {
      return convertDayNames(this.$t("general.calendar.dayNames"), "Sunday");
    },
    renderedTimeZone() {
      return `${this.timeZone} GMT${moment().tz(this.timeZone).format("Z")}`;
    },
  },

  methods: {
    ...mapActions(useSlotsStore, ["setAvailabilities"]),

    deleteAvailability(key, index) {
      this.availabilities[key].splice(index, 1);
    },
    onAvailableHoursChange(e, weekDay, type, index) {
      if (type === "start") {
        this.availabilities[weekDay][index].start = e;
      } else {
        this.availabilities[weekDay][index].end = e;
      }
      this.setAvailabilities(this.availabilities);
    },
    formatAvailabilityHours(datetime) {
      return DateTime.fromISO(datetime).toFormat("HH:mm");
    },
    changeCheckbox(e, key) {
      if (this.availabilities[key].length) {
        this.availabilities[key] = [];
      } else {
        this.availabilities[key].push({ start: "09:00", end: "17:00" });
        this.setAvailabilities(this.availabilities);
      }
    },
    onPlusButtonClick(weekDay) {
      if (!this.availabilities[weekDay].length) {
        this.availabilities[weekDay].push({ start: "09:00", end: "17:00" });
      } else {
        this.availabilities[weekDay].push({ start: "", end: "" });
      }
      this.setAvailabilities(this.availabilities);
    },
    getWeekDay(number) {
      return moment().day(number).format("dddd");
    },
    addDay(e, day, key) {
      if (day !== key) {
        if (e) {
          this.copyDays.push(day);
        } else {
          this.copyDays = this.copyDays.filter((copyDay) => copyDay !== day);
        }
      }
    },
    onApplyHandler(key) {
      if (this.availabilities[key].length) {
        this.copyDays.map((day) => {
          this.availabilities[day] = cloneDeep(this.availabilities[key]);
          this.availabilities[day].map((availableDay) => {
            availableDay.copied = true;
            return availableDay;
          });
        });
        for (const key in this.availabilities) {
          this.availabilities[key].menu = false;
        }
        this.setAvailabilities(this.availabilities);
        this.copyDays = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.weekly--overview__title {
  border-bottom: 2px solid #2196f3;
}
</style>

<style lang="scss">
.v-autocomplete.copied {
  background-color: #eff6ff;
  border: 1px solid #e7e8f2;
}

.v-autocomplete.copied .v-input__control .v-input__slot .v-select__slot input {
  color: #2196f3 !important;
}
</style>
